<template>
  <v-bottom-navigation
    v-model="value"
    class="d-md-none d-flex"
    color="primary"
    fixed
  >
    <v-btn @click="() => $router.push('/').catch(() => {})">
      <span class="">{{ $t('MobileNaviationBar.home') }}</span>

      <v-icon>mdi-home-outline</v-icon>
    </v-btn>

    <template v-if="isAuthenticated">
      <v-btn @click="() => $router.push(`/entities/${$route.params.entidadId}/cart`).catch(() => {})" v-if="items.length>0">
        <span>{{ $t('MobileNaviationBar.cart') }}</span>

        <v-icon>mdi-cart-outline</v-icon>
      </v-btn>

      <v-btn @click="() => $router.push(`/entities/${$route.params.entidadId}/account`).catch(() => {})">
        <span>{{ $t('MobileNaviationBar.account') }}</span>

        <v-icon>mdi-account-outline</v-icon>
      </v-btn>
    </template>

    <v-btn
      @click="() => $router.push(`/entities/${$route.params.entidadId}/signin`).catch(() => {})"
      v-else
    >
      <span>{{ $t('MobileNaviationBar.login') }}</span>

      <v-icon>mdi-account-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState({
        isAuthenticated: state => state.authentication.isAuthenticated,
        items: (state) => state.cart.items,
      }),
    },
    data(){ 
      return{
        value: 0
      }
    }
  }
</script>
